<!-- App.vue -->
<template>
  <div id="app">
    <div class="toggle-container">
      <button @click="toggleView">
        <i :class="isDataView ? 'fas fa-chart-bar' : 'fas fa-table'"></i>
        {{ isDataView ? "切换到图表" : "切换到数据" }}
      </button>
    </div>
    <h1 class="title">九曦农场自营品种上市/下市时间</h1>
    <gantt-chart v-if="!isDataView" :tasks="tasks" />
    <data-editor v-else :tasks="tasks" @update="updateTasks" />

    <div class="copyright">Powered By AI(99%) & Mask(1%) In Two Hours</div>
  </div>
</template>

<script>
import GanttChart from "./components/GanttChart.vue";
import DataEditor from "./components/DataEditor.vue";

export default {
  name: "App",
  components: {
    GanttChart,
    DataEditor,
  },
  data() {
    return {
      isDataView: false,
      tasks: [
        { name: "西红柿", startDate: "3.1", endDate: "6.30" },
        { name: "黄瓜", startDate: "4.15", endDate: "8.20" },
        { name: "茄子", startDate: "5.1", endDate: "9.15" },
        { name: "豆角", startDate: "5.20", endDate: "9.30" },
        { name: "辣椒", startDate: "6.1", endDate: "10.15" },
        { name: "南瓜", startDate: "6.15", endDate: "10.31" },
        { name: "西葫芦", startDate: "4.1", endDate: "8.31" },
        { name: "苦瓜", startDate: "5.15", endDate: "9.20" },
        { name: "丝瓜", startDate: "5.10", endDate: "9.25" },
        { name: "芹菜", startDate: "3.15", endDate: "6.15" },
        { name: "菠菜", startDate: "9.1", endDate: "11.30" },
        { name: "生菜", startDate: "3.1", endDate: "5.31" },
        { name: "韭菜", startDate: "4.1", endDate: "10.31" },
        { name: "大蒜", startDate: "9.15", endDate: "12.15" },
        { name: "洋葱", startDate: "3.1", endDate: "7.31" },
        { name: "胡萝卜", startDate: "3.15", endDate: "6.30" },
        { name: "土豆", startDate: "4.1", endDate: "8.31" },
        { name: "白萝卜", startDate: "8.1", endDate: "11.30" },
        { name: "芦笋", startDate: "4.15", endDate: "6.30" },
        { name: "青椒", startDate: "5.1", endDate: "9.30" },
      ],
    };
  },
  methods: {
    toggleView() {
      this.isDataView = !this.isDataView;
    },
    updateTasks(newTasks) {
      this.tasks = newTasks;
    },
  },
};
</script>

<style>
#app {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.toggle-container {
  margin-bottom: 20px;
}

.title {
  text-align: center;
  color: #143149;
  margin-bottom: 20px;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

button i {
  margin-right: 8px;
}

.copyright{ text-align: center; padding: 40px 0 14px; color: #999;}

@media (max-width: 768px) {
  #app {
    padding: 10px;
  }

  .title {
    font-size: 1.5em;
  }
}
</style>
