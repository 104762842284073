<!-- GanttChart.vue -->
<template>
  <div class="gantt-chart">
    <div class="header">
      <div class="task-label sticky">品种</div>
      <div class="months sticky">
        <div v-for="month in 12" :key="month" class="month">{{ month }}月</div>
      </div>
    </div>
    <div class="tasks">
      <div v-for="task in tasks" :key="task.name" class="task-row">
        <div class="task-label">{{ task.name }}</div>
        <div class="task-timeline">
          <div 
            class="task-bar" 
            :style="getTaskBarStyle(task)"
            :title="`${task.name}: ${task.startDate} - ${task.endDate}`"
          >
            <span class="task-start-date">{{ task.startDate }}</span>
            <span class="task-end-date">{{ task.endDate }}</span>
            <span class="task-details">{{ task.name }}: {{ task.startDate }} - {{ task.endDate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GanttChart',
  props: {
    tasks: Array
  },
  methods: {
    getTaskBarStyle(task) {
      const startMonth = parseFloat(task.startDate.split('.')[0])
      const endMonth = parseFloat(task.endDate.split('.')[0])
      const startDay = parseFloat(task.startDate.split('.')[1]) || 1
      const endDay = parseFloat(task.endDate.split('.')[1]) || 30

      const start = (startMonth - 1 + (startDay - 1) / 30) * (100/12)
      const duration = (endMonth - startMonth + (endDay - startDay) / 30) * (100/12)

      return {
        left: `${start}%`,
        width: `${duration}%`,
        backgroundColor: this.getRandomColor(task.name)
      }
    },
    getRandomColor(seed) {
      let hash = 0;
      for (let i = 0; i < seed.length; i++) {
        hash = seed.charCodeAt(i) + ((hash << 5) - hash);
      }
      const hue = hash % 360;
      return `hsl(${hue}, 70%, 60%)`;
    }
  }
}
</script>

<style scoped>
.gantt-chart {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header,
.task-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f9f9f9;
}

.task-label {
  width: 120px;
  flex-shrink: 0;
  padding: 8px;
  font-weight: bold;
  background-color: #ecf3ee; /* 淡紫色 */
  color: #204565;
}

.sticky {
  position: sticky;
  left: 0;
  z-index: 5;
}

.months,
.task-timeline {
  display: flex;
  flex-grow: 1;
}

.months > div {
  flex: 1;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  color: #555;
}

.months {
  position: relative;
}

.month {
  position: relative;
  z-index: 1;
}

.months::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: repeating-linear-gradient(
    to right,
    rgba(230, 255, 230, 0.5) 0%,
    /* 淡绿色 */ rgba(230, 255, 230, 0.5) 8.33%,
    rgba(255, 240, 230, 0.5) 8.33%,
    /* 淡橙色 */ rgba(255, 240, 230, 0.5) 16.66%
  );
  z-index: 0;
}

.task-timeline {
  position: relative;
  height: 36px;
  background: repeating-linear-gradient(
    to right,
    rgba(230, 255, 230, 0.3) 0%,
    rgba(230, 255, 230, 0.3) 8.33%,
    rgba(255, 240, 230, 0.3) 8.33%,
    rgba(255, 240, 230, 0.3) 16.66%
  );
}

.task-bar {
  position: absolute;
  height: 20px;
  top: 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.task-bar:hover {
  transform: scale(1.05);
}

.task-start-date,
.task-end-date {
  position: absolute;
  top: 1px;
  font-size: 12px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #000;
  padding: 2px 4px;
  border-radius: 2px;
  white-space: nowrap;
}

.task-start-date {
  left: 0;
  transform: translateX(-100%);
}

.task-end-date {
  right: 0;
  transform: translateX(100%);
}

.task-details {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}

.task-bar:hover .task-details {
  display: block;
}

@media (max-width: 768px) {
  .task-label {
    width: 80px;
    font-size: 12px;
    font-weight: normal;
  }
  
  .months > div {
    padding: 6px 0;
    font-size: 12px;
  }
  
  .task-timeline {
    height: 28px;
  }
  
  .task-bar {
    height: 16px;
    top: 6px;
  }

  .task-start-date,
  .task-end-date,
  .task-details {
    font-size: 9px;
  }
}
</style>
