<!-- DataEditor.vue -->
<template>
  <div class="data-editor">
    <div v-for="(task, index) in localTasks" :key="index" class="task-container">
      <div class="task-input">
        <input v-model="task.name" placeholder="品种 (1-8字)" maxlength="8" />
        <input v-model="task.startDate" placeholder="开始日期 (如: 8.5)" />
        <input v-model="task.endDate" placeholder="结束日期 (如: 9.20)" />
      </div>
      <div class="task-actions">
        <button @click="moveTask(index, -1)" :disabled="index === 0" class="move-btn">
          <i class="fas fa-arrow-up"></i>
        </button>
        <button @click="moveTask(index, 1)" :disabled="index === localTasks.length - 1" class="move-btn">
          <i class="fas fa-arrow-down"></i>
        </button>
        <button @click="removeTask(index)" class="remove-btn">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <button @click="addTask" class="add-btn">
      <i class="fas fa-plus"></i>
      添加新任务
    </button>
    <button @click="saveTasks" class="save-btn">
      <i class="fas fa-save"></i>
      保存更改
    </button>
  </div>
</template>

<script>
export default {
  name: "DataEditor",
  props: {
    tasks: Array,
  },
  data() {
    return {
      localTasks: [...this.tasks],
    };
  },
  methods: {
    addTask() {
      this.localTasks.push({ name: "", startDate: "", endDate: "" });
    },
    removeTask(index) {
      this.localTasks.splice(index, 1);
    },
    saveTasks() {
      this.$emit("update", this.localTasks);
    },
    moveTask(index, direction) {
      const newIndex = index + direction;
      if (newIndex >= 0 && newIndex < this.localTasks.length) {
        const task = this.localTasks.splice(index, 1)[0];
        this.localTasks.splice(newIndex, 0, task);
      }
    },
  },
};
</script>

<style scoped>
.data-editor {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-container {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.task-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.task-actions {
  display: flex;
  justify-content: flex-end;
}

input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 0;
}

.move-btn,
.remove-btn,
.add-btn,
.save-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.move-btn {
  background-color: #4caf50;
  color: white;
}

.remove-btn {
  background-color: #ff4d4d;
  color: white;
}

.add-btn {
  background-color: #4caf50;
  color: white;
  margin-right: 10px;
}

.save-btn {
  background-color: #2196f3;
  color: white;
}

.move-btn:hover,
.add-btn:hover {
  background-color: #45a049;
}

.remove-btn:hover {
  background-color: #ff3333;
}

.save-btn:hover {
  background-color: #0b7dda;
}

button i {
  margin-right: 8px;
}

@media (max-width: 769px) {
  input {
    width: 20px;
  }

  .task-actions {
    align-items: center;
  }
}
</style>
